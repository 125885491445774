<template>
  <div class="audio-mixer">
    <!-- Sliders for adjusting volume -->
    <div class="controls"></div>

    <div class="audio-segments w-100">
      <div class="segment intro">
        <div
          class="volume-div"
          :style="{
            height: `${introVolume}%`,
          }"
        ></div>
        <span class="volume-span">Volume : {{ introVolume }}%</span>
        <div class="relative">INTRO</div>
      </div>
      <div class="voice-fond">
        <div class="segment voice w-100 mb-1">
          <div
            class="volume-div"
            :style="{
              height: `${voiceVolume}%`,
            }"
          ></div>
          <span class="volume-span">Volume : {{ voiceVolume }}%</span>
          <div class="relative">VOIX</div>
        </div>
        <div class="segment fond">
          <div
            class="volume-div"
            :style="{
              height: `${fondVolume}%`,
            }"
          ></div>
          <span class="volume-span">Volume : {{ fondVolume }}%</span>
          <div class="relative">FOND</div>
        </div>
      </div>
      <div class="segment outro w-100">
        <div
          class="volume-div"
          :style="{
            height: `${outroVolume}%`,
          }"
        ></div>
        <span class="volume-span">Volume : {{ outroVolume }}%</span>
        <div class="relative">OUTRO</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    voiceVolume: Number,
    introVolume: Number,
    fondVolume: Number,
    outroVolume: Number,
  },
}
</script>
<style scoped>
.audio-mixer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.voice-fond {
  width: 60%;
  height: 100px;
}
.voice {
  position: relative;
  background: #4db6ac;
  height: 50px;
}
.fond {
  height: 46px;
  position: relative;
  background: #cfd8dc;
}
.volume-span {
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  font-weight: 300;
}
.volume-div {
  transition: 0.3s;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: 1px solid darkgray;
  opacity: 0.5;
  bottom: 0;
}
.outro,
.intro {
  position: relative;
  margin: 0 4px;
  width: 20% !important;
  background: #4fc3f7;
  height: 100px;
}
.audio-segments {
  display: flex;
  margin-top: 20px;
  width: 100%;
}
.segment {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-weight: bold;
}
</style>
