var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"audio-mixer"},[_c('div',{staticClass:"controls"}),_c('div',{staticClass:"audio-segments w-100"},[_c('div',{staticClass:"segment intro"},[_c('div',{staticClass:"volume-div",style:({
          height: (_vm.introVolume + "%"),
        })}),_c('span',{staticClass:"volume-span"},[_vm._v("Volume : "+_vm._s(_vm.introVolume)+"%")]),_c('div',{staticClass:"relative"},[_vm._v("INTRO")])]),_c('div',{staticClass:"voice-fond"},[_c('div',{staticClass:"segment voice w-100 mb-1"},[_c('div',{staticClass:"volume-div",style:({
            height: (_vm.voiceVolume + "%"),
          })}),_c('span',{staticClass:"volume-span"},[_vm._v("Volume : "+_vm._s(_vm.voiceVolume)+"%")]),_c('div',{staticClass:"relative"},[_vm._v("VOIX")])]),_c('div',{staticClass:"segment fond"},[_c('div',{staticClass:"volume-div",style:({
            height: (_vm.fondVolume + "%"),
          })}),_c('span',{staticClass:"volume-span"},[_vm._v("Volume : "+_vm._s(_vm.fondVolume)+"%")]),_c('div',{staticClass:"relative"},[_vm._v("FOND")])])]),_c('div',{staticClass:"segment outro w-100"},[_c('div',{staticClass:"volume-div",style:({
          height: (_vm.outroVolume + "%"),
        })}),_c('span',{staticClass:"volume-span"},[_vm._v("Volume : "+_vm._s(_vm.outroVolume)+"%")]),_c('div',{staticClass:"relative"},[_vm._v("OUTRO")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }