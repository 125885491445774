<template>
  <b-row>
    <b-col cols="12">
      <b-row
        class="mt-4"
        v-for="(message, index) in messagesToVoice"
        :key="index"
      >
        <b-col cols="12">
          <h-card>
            <div class="d-flex justify-content-between w-100">
              <div
                class="d-flex align-items-center flex-grow"
                v-b-toggle="'messageVoice' + index"
              >
                <b-icon
                  :icon="
                    messageCollapse.ite[index]
                      ? 'dash-circle-fill'
                      : 'plus-circle-fill'
                  "
                  class="mr-3 mt-1"
                ></b-icon>
                <h5 class="h5 mb-3">Message {{ index + 1 }}</h5>
              </div>
              <div
                class="d-flex align-items-center py-2 audio-balise"
                v-if="messageVoiceState[index] === 'done'"
              >
                <audio
                  class="w-100"
                  ref="audioVoice"
                  :src="audioSRC(index)"
                  controls
                ></audio>
              </div>
              <div
                v-else-if="messageVoiceState[index] === 'running'"
                class="d-flex align-items-center py-2"
                :key="'running'"
              >
                <p class="ml-3 mt-3">
                  Génération de l'audio du message en cours...
                </p>
              </div>
              <div
                v-else-if="messageVoiceState[index] === 'compositing'"
                class="d-flex align-items-center py-2"
                :key="'compositing'"
              >
                <p class="ml-3 mt-3">Composition du message en cours...</p>
              </div>
              <h-button
                v-if="
                  messageVoiceState[index] !== 'done' ||
                    (messageVoiceState[index] === 'done' &&
                      selectedCompositing[index].id > 0)
                "
                :disabled="
                  messageVoiceState[index] === 'running' ||
                    messageVoiceState[index] === 'compositing' ||
                    !voiceGender[index] ||
                    !selectedCompositing[index]
                "
                @click.native.prevent="
                  (event) =>
                    messageVoiceState[index] === 'done'
                      ? generateComposer(event, index)
                      : generateVoiceMessage(event, index)
                "
                blue
                outline
                class="align-self-center"
              >
                <b-icon icon="soundwave" class="w-17px mr-2" font-scale="1">
                </b-icon>
                {{
                  messageVoiceState[index] === 'done'
                    ? 'Regénérer la voix du message'
                    : 'Générer la voix du message'
                }}
              </h-button>
            </div>

            <b-collapse
              v-model="messageCollapse.ite[index]"
              :id="'messageVoice' + index"
              class="px-4 pb-3"
            >
              <b-row>
                <b-col md="5" class="mb-4">
                  <h5 class="mb-2">Paramètres de la voix</h5>
                  <b-row>
                    <b-col cols="12" md="12" lg="6">
                      <h-card
                        class=" mt-4 h-100 "
                        isClickable
                        @click="setVoiceGender(index, 'woman')"
                        :isActive="
                          voiceGender[index] && voiceGender[index] === 'woman'
                        "
                      >
                        <img
                          src="@/assets/svg/illu_woman_voice.svg"
                          height="80px"
                          alt="ia message"
                        />
                        <h5 class="text-center mt-4 mb-0">Voix féminine</h5>
                      </h-card>
                    </b-col>
                    <b-col cols="12" md="12" lg="6" class="mt-3 mt-lg-0">
                      <h-card
                        class="mt-4 h-100"
                        isClickable
                        @click="setVoiceGender(index, 'man')"
                        :isActive="
                          voiceGender[index] && voiceGender[index] === 'man'
                        "
                      >
                        <img
                          src="@/assets/svg/illu_man_voice.svg"
                          height="80px"
                          alt="ia message"
                          class="mx-auto"
                        />
                        <h5 class="text-center mt-4 mb-0">
                          Voix masculine
                        </h5>
                      </h-card>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  md="7"
                  class="mb-4"
                  v-if="compositingOptions && compositingOptions.length > 1"
                >
                  <h5 class="mb-2">Options de composition</h5>
                  <div class="margin-comp">
                    <h-select
                      class="mt-2"
                      :value="selectedCompositing[index]"
                      @input="updateSelectedCompositing(index, $event)"
                      placeholder="Choisir un habillage de composition"
                      left-icon="input-cursor"
                      :options="compositingOptions"
                      notClearable
                    ></h-select>
                  </div>
                  <b-col
                    cols="12"
                    class="mt-3"
                    v-if="
                      selectedCompositing[index] &&
                        selectedCompositing[index].id > 0
                    "
                  >
                    <p>
                      Les options de composition enrichissent votre message.
                      Elles comprennent un jingle d'introduction, une ambiance
                      sonore de fond, ainsi qu'un jingle de conclusion.
                      <br />
                      Vous pouvez ajuster le volume et le décalage de chacun de
                      ces éléments selon vos préférences.
                    </p>

                    <MessageCompositing
                      :voiceVolume="voiceVolume[index]"
                      :introVolume="introVolume[index]"
                      :fondVolume="bedVolume[index]"
                      :outroVolume="outroVolume[index]"
                    />
                    <div>
                      <b-row>
                        <b-col md="6">
                          <h6>Gestion des volumes</h6>
                          <div class="d-flex ">
                            <div
                              class="d-flex flex-column align-items-center mx-3"
                            >
                              <vue-slider
                                :min="0"
                                :value="introVolume[index]"
                                @change="
                                  (value) => setIntroVolume(value, index)
                                "
                                direction="btt"
                                :height="150"
                              />
                              <p class="pt-2">Intro</p>
                            </div>
                            <div
                              class="d-flex flex-column align-items-center mx-3"
                            >
                              <vue-slider
                                :min="0"
                                :value="voiceVolume[index]"
                                @change="
                                  (value) => setVoiceVolume(value, index)
                                "
                                direction="btt"
                                :height="150"
                              />
                              <p class="pt-2">Voix</p>
                            </div>
                            <div
                              class="d-flex flex-column align-items-center mx-3"
                            >
                              <vue-slider
                                :min="0"
                                :value="bedVolume[index]"
                                @change="(value) => setBedVolume(value, index)"
                                direction="btt"
                                :height="150"
                              />
                              <p class="pt-2">Fond</p>
                            </div>
                            <div
                              class="d-flex flex-column align-items-center mx-3"
                            >
                              <vue-slider
                                :min="0"
                                :value="outroVolume[index]"
                                @change="
                                  (value) => setOutroVolume(value, index)
                                "
                                direction="btt"
                                :height="150"
                              />
                              <p class="pt-2">Outro</p>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" class="d-flex flex-column">
                          <h6>Gestion des décalages (en milisecondes)</h6>
                          <div class="d-flex flex-column">
                            <div class="d-flex flex-column">
                              <p class="mb-0">Fond</p>
                              <vue-slider
                                class="pl-1"
                                :min="-2000"
                                :max="2000"
                                :interval="100"
                                :marks="marks"
                                :value="bedNudge[index]"
                                @change="(value) => setBedNudge(value, index)"
                                :process="false"
                              />
                            </div>
                            <p class="mt-4 mb-0">Voix</p>
                            <vue-slider
                              class="pl-1"
                              :min="-2000"
                              :max="2000"
                              :marks="marks"
                              :interval="100"
                              :value="voiceNudge[index]"
                              @change="(value) => setVoiceNudge(value, index)"
                              :process="false"
                            />
                            <p class="mt-4 mb-0">Outro</p>
                            <vue-slider
                              class="pl-1"
                              :min="-2000"
                              :max="2000"
                              :marks="marks"
                              :interval="100"
                              :value="outroNudge[index]"
                              @change="(value) => setOutroNudge(value, index)"
                              :process="false"
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-col>
              </b-row>
            </b-collapse>
          </h-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import MessageCompositing from '@/components/message/MessageCompositing'

export default {
  components: {
    VueSlider,
    MessageCompositing,
  },
  data() {
    return {
      marks: [-2000, -1000, 0, 1000, 2000],
      messageCollapse: {
        ite: [true],
      },
    }
  },
  mounted() {
    this.$webSocketsConnect()
  },
  computed: {
    ...mapState({
      newMessageComp: (state) => state.message.newMessage,
      messageVoiceState: (state) => state.message.newMessage.messageVoiceState,
      messagesToVoice: (state) =>
        state.message.newMessage.messagesContent.filter((e) => e.selected),
    }),
    compositingOptions() {
      const compositingOptionsStore = this.$store.state.message
        .compositingOptions

      if (!compositingOptionsStore) return
      const compositingForSelect = compositingOptionsStore.map((item) => {
        return {
          label: item.name,
          id: item.id,
        }
      })
      return compositingForSelect
    },
    selectedCompositing() {
      return this.$store.state.message.newMessage.selectedCompositing
    },
    voiceGender: {
      // getter
      get() {
        return this.$store.state.message.newMessage.voiceGender
      },
      // setter
      set({ index, gender }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'voiceGender',
          value: gender,
          index: index,
        })
      },
    },
    introVolume: {
      get() {
        return this.$store.state.message.newMessage.Volume0
      },
      set({ index, vol }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Volume0',
          value: vol,
          index: index,
        })
      },
    },
    bedVolume: {
      get() {
        return this.$store.state.message.newMessage.Volume1
      },
      set({ index, vol }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Volume1',
          value: vol,
          index: index,
        })
      },
    },
    outroVolume: {
      get() {
        return this.$store.state.message.newMessage.Volume2
      },
      set({ index, vol }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Volume2',
          value: vol,
          index: index,
        })
      },
    },
    voiceVolume: {
      get() {
        return this.$store.state.message.newMessage.Volume3
      },
      set({ index, vol }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Volume3',
          value: vol,
          index: index,
        })
      },
    },
    introNudge: {
      get() {
        return this.$store.state.message.newMessage.Nudge0
      },
      set(nudge) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Nudge0',
          value: nudge,
        })
      },
    },
    bedNudge: {
      get() {
        return this.$store.state.message.newMessage.Nudge1
      },
      set({ nudge, index }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Nudge1',
          value: nudge,
          index: index,
        })
      },
    },
    outroNudge: {
      get() {
        return this.$store.state.message.newMessage.Nudge2
      },
      set({ nudge, index }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Nudge2',
          value: nudge,
          index: index,
        })
      },
    },
    voiceNudge: {
      get() {
        return this.$store.state.message.newMessage.Nudge3
      },
      set({ index, nudge }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Nudge3',
          value: nudge,
          index: index,
        })
      },
    },
  },
  watch: {
    selectedCompositing: {
      handler(newVal) {
        //A changer car ça remet à la valeur initial tous les sliders
        newVal.forEach((item, index) => {
          this.$store.dispatch('message/_setNudgeVolumeData', {
            index: index,
            id: item.id,
          })
        })
      },
      deep: true,
    },
  },
  methods: {
    setVoiceGender(index, gender) {
      this.voiceGender = { index: index, gender: gender }
    },
    updateSelectedCompositing(index, value) {
      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'selectedCompositing',
        value,
        index,
      })
    },
    setIntroVolume(value, index) {
      this.introVolume = { index: index, vol: value }
    },
    setVoiceVolume(value, index) {
      this.voiceVolume = { index: index, vol: value }
    },
    setBedVolume(value, index) {
      this.bedVolume = { index: index, vol: value }
    },
    setOutroVolume(value, index) {
      this.outroVolume = { index: index, vol: value }
    },
    setBedNudge(value, index) {
      this.bedNudge = { index: index, nudge: value }
    },
    setVoiceNudge(value, index) {
      this.voiceNudge = { index: index, nudge: value }
    },
    setOutroNudge(value, index) {
      this.outroNudge = { index: index, nudge: value }
    },
    generateVoiceMessage(event, index) {
      event.stopPropagation()
      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'fileuuid',
        value: null,
        index: index,
      })

      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'messageVoiceState',
        value: 'running',
        index: index,
      })

      if (!this.messagesToVoice) return
      const generateVoice = JSON.stringify({
        Content: {
          IdTransaction: this.$store.state.message.newMessage.idTransaction,
          Parameters: {
            Text: this.messagesToVoice[index].text,
            Voice: this.voiceGender[index],
          },
          Provider: 'ElevenLabs',
        },
        Header: {
          Type: 'ProviderRequest',
          idbrand: this.$store.state.user.user.visibleBrands[0].id,
          Tag: `ElevenLabs-${index}`,
        },
      })
      this.$webSocketsSend(generateVoice)
    },
    fileuuidFromWs(index) {
      if (this.newMessageComp.fileuuidComposit[index])
        return this.newMessageComp.fileuuidComposit[index]
      else return this.newMessageComp.fileuuid[index]
    },
    audioSRC(index) {
      return `https://fg0test.radioshop.net:8088/file?uuid=${this.fileuuidFromWs(
        index
      )}`
    },
    updateAudioSource() {
      const audioElement = this.$refs.audioElement

      if (audioElement) {
        audioElement.pause()
        audioElement.currentTime = 0
        audioElement.src = this.audioSRC
        audioElement.load()
        audioElement.play()
      }
    },
    generateComposer(event, index) {
      event.stopPropagation()
      if (!this.selectedCompositing[index])
        this.selectedCompositing[index] = {
          name: 'Aucune composition',
          id: -1,
        }
      if (this.selectedCompositing[index].id == -1) {
        this.$removeCompositing(index)
        return
      }
      const fileUuid = this.$store.state.message.newMessage.fileuuid[index]
      this.$sendCompositing(fileUuid, index)
    },
  },
}
</script>

<style lang="scss" scoped>
h6 {
  color: #848c9c;
}
p {
  color: #70798b;
}

.margin-comp {
  margin-top: 32px;
}

button.disabled {
  pointer-events: none;
}

.audio-balise {
  width: 400px;
}
</style>
